<template>
  <section class="register-section section-space-b pt-4 pt-md-5 mt-md-3">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-11 col-md-11 col-sm-11">
          <!-- <form action="#" class="form-create mb-5 mb-lg-0"> -->
            <div class="section-head-sm ps-4">
              <h2 class="mb-2" v-html="SectionData.registerData.title"></h2>
            </div>
            <div class="form-item mb-4 d-flex">
              <div class="container">
                <div class="mb-4">
                  <h4 class="mb-2">ID</h4>
                  <input type="text" class="form-control" id="userName" placeholder="ID" v-model="credentials.memberId" autocomplete="off" />
                </div>
                <div class="mb-4">
                  <h4 class="mb-2">密码</h4>
                  <input type="password" class="form-control password" id="password" placeholder="密码" v-model="credentials.memberPassword" autocomplete="off" />
                </div>
                <!-- end form-floating -->
              </div>
              <!-- bio start -->
              <div class="mb-4 pb-4 container">
                <h4 class="mb-2">简介</h4>
                <textarea type="password" class="form-control" style="height: 100%" id="bio" placeholder="在这里写下你的自我介绍" v-model="credentials.memberBio" autocomplete="off"></textarea>
              </div>
              <!-- bio end-->
              <!-- end form-floating -->
            </div>
          <!-- </form> -->
          <!-- <p class="mb-4 form-text text-end">{{ SectionData.registerData.termText }}</p> -->
          <button class="btn btn-dark ms-5" type="submit" @click="signup(credentials)">{{ SectionData.registerData.btnText }}</button>

          <p class="mt-3 form-text ps-5">
            {{ SectionData.registerData.haveAccountText }} <router-link :to="SectionData.registerData.btnTextLink" class="btn-link">{{ SectionData.registerData.btnTextTwo }}</router-link>
          </p>
        </div>
        <!-- end col-lg-6 -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- end register-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";
import { mapActions } from "vuex";
export default {
  name: "RegisterSection",
  data() {
    return {
      SectionData,
      credentials: {
        memberId: null,
        memberPassword: null,
        memberBio: null,
        file: null,
      },
    };
  },
  methods: {
    selectFile(data) {
      this.credentials.file = data.target.files[0];
    },
    ...mapActions(["signup"]),
  },
  mounted() {
    /*  ======== Show/Hide passoword ======== */
    function showHidePassword(selector) {
      let elem = document.querySelectorAll(selector);
      if (elem.length > 0) {
        elem.forEach((item) => {
          item.addEventListener("click", function (e) {
            e.preventDefault();
            let target = document.getElementById(item.getAttribute("href"));
            if (target.type == "password") {
              target.type = "text";
              item.classList.add("is-shown");
            } else {
              target.type = "password";
              item.classList.remove("is-shown");
            }
          });
        });
      }
    }
    function fileUpload(selector) {
      let elem = document.querySelectorAll(selector);
      if (elem.length > 0) {
        elem.forEach((item) => {
          item.addEventListener("change", function () {
            var target = document.getElementById(item.dataset.target);
            var allowedExtensions = ["jpg", "png", "gif", "webp", "mp4", "mp3"];
            var fileExtension = this.value.split(".").pop();
            var lastDot = this.value.lastIndexOf(".");
            var ext = this.value.substring(lastDot + 1);
            var extTxt = (target.value = ext);

            if (!allowedExtensions.includes(fileExtension)) {
              alert(extTxt + " file type not allowed, Please upload jpg, png, gif, webp, mp4 or mp3 file");
              target.innerHTML = "Please upload jpg, png, gif, webp, mp4 or mp3 file";
            } else {
              target.innerHTML = item.files[0].name;
            }
          });
        });
      }
    }

    fileUpload(".file-upload-input");

    showHidePassword(".password-toggle");
  },
};
</script>
