<template>
  <footer class="footer-section" :class="classname">
    <!-- footer section -->
    <FooterSection></FooterSection>
  </footer>
  <!-- end footer-section -->
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  props: ["classname"],
};
</script>

<style scoped>
</style>