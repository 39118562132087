/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Datepicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css";

const app = createApp(App)
app.use(store)
// bootstrap
import 'bootstrap'

// vue select 选择组件
import vSelect from 'vue-select'
// import 'vue-select/dist/vue--select.css'

// 注册组件
app.component('v-select',vSelect)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Datapicker', Datepicker)

// clipboard    一个复制粘贴的包
import VueClipboard from 'vue3-clipboard'
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.use(router).mount("#app");

import "./assets/scss/bundles.scss";
import "./assets/scss/style.scss";

import HeaderMain from "./components/common/HeaderMain.vue";
import LogoLink from "./components/common/LogoLink.vue";
import HowItWork from './components/section/HowItWork.vue'
import FullScreenSlider from "./components/common/FullScreenSlider.vue"
import ButtonGroup from "./components/common/ButtonGroup.vue";
import Featured from "./components/section/Featured.vue";
import SectionHeading from "./components/common/SectionHeading.vue";
import ProductsContainer from "./components/section/ProductsContainer.vue";
import ButtonLink from "./components/common/ButtonLink.vue";

import Footer from "./pages/Footer.vue";
import FooterSection from "./components/section/FooterSection.vue";

import MainSectionHeading from "./components/common/MainSectionHeading.vue";

import LoginSection from "./components/section/LoginSection.vue";
import RegisterSection from "./components/section/RegisterSection.vue";

import AuthorHero from "./components/section/AuthorHero.vue";
import ProfileSection from "./components/section/ProfileSection.vue";
import AuthorSidebar from "./components/common/AuthorSidebar.vue";
import UserSidebar from "./components/common/UserSidebar.vue";
import AccountSection from "./components/section/AccountSection.vue";
import ExploreSection from "./components/section/ExploreSection.vue";
import ExhibitionCards from "./components/section/ExhibitionCards.vue";
import ExhibitionCarousel from "./components/section/ExhibitionCarousel.vue";
import CarouselScreenSlider from "./components/common/CarouselScreenSlider.vue";


app.component("HeaderMain", HeaderMain);
app.component("LogoLink", LogoLink);
app.component('HowItWork',HowItWork)
app.component('FullScreenSlider',FullScreenSlider)
app.component('ButtonGroup',ButtonGroup)
app.component("Featured", Featured);
app.component("SectionHeading", SectionHeading);
app.component("ProductsContainer", ProductsContainer);
app.component("ButtonLink", ButtonLink);
app.component("Footer", Footer);
app.component("FooterSection", FooterSection);
app.component("MainSectionHeading", MainSectionHeading);
app.component("LoginSection", LoginSection);
app.component("RegisterSection", RegisterSection);

app.component("AuthorHero", AuthorHero);
app.component("ProfileSection", ProfileSection);
app.component("AuthorSidebar", AuthorSidebar);
app.component("ExploreSection", ExploreSection);
app.component("ExhibitionCards", ExhibitionCards);
app.component("ExhibitionCarousel", ExhibitionCarousel);
app.component("CarouselScreenSlider", CarouselScreenSlider);
app.component("UserSidebar", UserSidebar);
app.component("AccountSection", AccountSection);
