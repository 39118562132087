<template>
  <div class="header-logo">
        <!-- logo link -->
        <LogoLink></LogoLink>
    </div><!-- .header-logo -->
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Logo'
}
</script>
