import { createRouter, createWebHistory } from "vue-router"

const routes = [
{
  path: '/',
  name: 'Home',
  component: () => import('../pages/Home.vue')
},
{
  path: "/login",
  name: "login",
  component: () => import("../pages/Login.vue"),
},
{
  path: "/register",
  name: "Register",
  component: () => import("../pages/Register.vue"),
},
{
  path: "/profile/:id",
  name: "profile",
  component: () => import("../pages/Profile.vue"),
},
{
  path: "/explore",
  name: "explore",
  component: () => import("../pages/Explore.vue"),
},
{
  path: "/privateGallery",
  name: "PrivateGallery",
  component: () => import("../pages/PrivateGallery.vue"),
},
{
  path: "/exhibition",
  name: "Exhibition",
  component: () => import("../pages/Exhibition.vue"),
},
{
  path: "/account/:id",
  name: "account",
  component: () => import("../pages/Account.vue"),
},
]


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savePosition) {
    if (savePosition) {
      return savePosition
    } else {
      return {
        left: 0,
        top: 0,
      }
    }
  }
})


export default router;