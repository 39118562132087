<template>
  <div v-if="this.product.nft.nftOwnerAddress != myAddress">
    <button type="button" data-bs-toggle="modal" :data-bs-target="`#modal` + `${product.marketId}`" class="btn btn-sm btn-dark d-block">Purchase</button>
    <div class="modal fade" :id="`modal${product.marketId}`" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <div>{{ product.marketId }}</div> -->
            <h4 class="modal-title">Complete checkout</h4>
            <button type="button" class="btn-close icon-btn" data-bs-dismiss="modal" aria-label="Close">
              <em class="ni ni-cross"></em>
            </button>
          </div>
          <!-- end modal-header -->
          <!-- <p class="">hihihi</p> -->
          <div class="modal-header">If you'd like to purchase,<strong>Please sign for this transaction</strong></div>
          <div class="modal-body">
            <!-- <div class="mb-3">
              <label class="form-label">아아</label>
              <input type="text" class="form-control form-control-s1" v-model="authorPrivateKey" placeholder="please typing your Private Key" />
            </div> -->
            <div class="mb-3">
              <label class="form-label">Your Private Key</label>
              <input type="text" class="form-control form-control-s1" v-model="authorPrivateKey" placeholder="please typing your Private Key" />
            </div>
            <button class="btn btn-dark d-block" @click="purchaseNFT" :data-bs-target="`#modal` + `${product.marketId}`" data-bs-toggle="modal">Confirm</button>
            <!-- <a :href="SectionData.purchaseNFTModal.btnLink" class="btn btn-dark d-block" @click="purchaseNFT"></a> -->
          </div>
          <!-- end modal-body -->
        </div>
        <!-- end modal-content -->
      </div>
      <!-- end modal-dialog -->
    </div>
    <!-- end firstmodal -->
    <!-- start second modal -->
    <div class="modal fade" :id="`modal${product.marketId}`" tabindex="-1" aria-hidden="true" v-if="this.purchase == 1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header d-flex flex-column">
            <h4 class="modal-title d-flex justify-content-center">You get {{ product.nft.nftName }}!!</h4>
            <button type="button" class="btn-close icon-btn" data-bs-dismiss="modal" aria-label="Close">
              <em class="ni ni-cross"></em>
            </button>
            <p class="d-flex justify-content-center">Wow! You just create your NFT</p>
          </div>
          <!-- end second modal-header -->
          <div class="modal-body d-flex flex-column">
            <div class="mb-3 d-flex justify-content-center">
              <img src="" class="justify-content-center" alt="" />
              <!-- <img src=`${}` alt="" /> -->
            </div>
            <!-- <a :href="SectionData.purchaseNFTModal.btnLink" class="btn btn-dark d-block" @click="purchaseNFT"></a> -->
          </div>
          <!-- end second modal-body -->
        </div>
        <!-- end second modal-content -->
      </div>
      <!-- end second modal-dialog -->
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import axios from "axios";
import SectionData from "@/store/store.js";


export default {
  name: "Purchase",
  data() {
    return {
      authorPrivateKey: null,
      marketContractAddress: this.product.marketContractAddress,
      price: this.product.price,
      nftOwnerAddress: this.product.nftOwnerAddress,
      nftTokenId: this.product.nft.nftTokenId,
      modalId: "",
      modalLink: "",
      purchase: 0,
    };
  },
  props: ["product", "marketId"],
  methods: {
   
  },
  created() {
   
  },
  computed: {
    ...mapState(["myAddress"]),
    ...mapState(["authToken"]),
  },
};
</script>

<style></style>
